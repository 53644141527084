import React, { useState } from 'react';
import { Container, Grow, Grid, AppBar, TextField, Button, Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ChipInput from 'material-ui-chip-input';

import { getPostsBySearch } from '../../actions/posts';
import Posts from '../Posts/Posts';
import Form from '../Form/Form';
import Pagination from '../Pagination';
import useStyles from './styles';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Home = () => {
  const classes = useStyles();
  const query = useQuery();
  const page = query.get('page') || 1;
  const searchQuery = query.get('searchQuery');

  const [currentId, setCurrentId] = useState(0);
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  // const [tags, setTags] = useState([]);
  const [tags, setTags] = useState('');
  const history = useHistory();

  const searchPost = () => {
    // console.log("tags, search", tags, search);
    if (search.trim() || tags) {
      // dispatch(getPostsBySearch({ search, tags: tags.join(',') }));
      // history.push(`/posts/search?searchQuery=${search || 'none'}&tags=${tags.join(',')}`);
      dispatch(getPostsBySearch({ search, tags }));
      history.push(`/sims/search?searchQuery=${search || 'none'}&tags=${tags}`);
    } else {
      history.push('/');
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      searchPost();
    }
  };

  // const handleAddChip = (tag) => setTags([...tags, tag]);

  // const handleDeleteChip = (chipToDelete) => setTags(tags.filter((tag) => tag !== chipToDelete));

  return (
    <Grow in>
      <Container maxWidth="xl">
        <Grid
          container
          justify="space-between"
          alignItems="stretch"
          spacing={3}
          className={classes.gridContainer}
        >
          <Grid item xs={12} sm={6} md={9}>
            <Posts setCurrentId={setCurrentId} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBar
              className={classes.appBarSearch}
              position="static"
              color="inherit"
            >
              <TextField
                onKeyDown={handleKeyPress}
                name="search"
                variant="outlined"
                // label="Search Simulators"
                label="جستجوی شبیه‌ساز"
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {/* <ChipInput
                style={{ margin: "10px 0" }}
                value={tags}
                // onAdd={(chip) => handleAddChip(chip)}
                // onDelete={(chip) => handleDeleteChip(chip)}
                onChange={(e) => setTags(e.target.value)}
                // label="Search Tags"
                label="جستجوی برچسب"
                variant="outlined"
              /> */}
              <TextField
                onKeyDown={handleKeyPress}
                style={{ margin: "10px 0" }}
                name="tags"
                variant="outlined"
                // label="Search Simulators"
                label="جستجوی برچسب"
                fullWidth
                value={tags}
                onChange={(e) => setTags(e.target.value)}
              />
              <Button
                onClick={searchPost}
                className={classes.searchButton}
                variant="contained"
                color="primary"
              >
                {/* Search */}
                جستجو
              </Button>
            </AppBar>
            <Form currentId={currentId} setCurrentId={setCurrentId} />
            {!searchQuery && !tags.length && (
              <Paper className={classes.pagination} elevation={6}>
                <Pagination page={page} />
              </Paper>
            )}
          </Grid>
        </Grid>
      </Container>
    </Grow>
  );
};

export default Home;
