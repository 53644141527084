import React, { useEffect } from "react";
import {
  Paper,
  // Typography,
  CircularProgress,
  // Divider,
} from "@material-ui/core/";
import { useDispatch, useSelector } from "react-redux";
// import moment from "moment";
import { useParams, useHistory, Link } from "react-router-dom";

import { getPost, getPostsBySearch } from "../../actions/posts";
// import CommentSection from "./CommentSection";
import useStyles from "./styles";

const Post = () => {
  const { post, posts, isLoading } = useSelector((state) => state.posts);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getPost(id));
  }, [id]);

  useEffect(() => {
    if (post) {
      dispatch(
        getPostsBySearch({ search: "none", tags: post?.tags.join(",") })
      );
    }
  }, [post]);

  if (!post) return null;
  // console.log(post);
  // const openPost = (_id) => history.push(`/posts/${_id}`);

  if (isLoading) {
    return (
      <Paper elevation={6} className={classes.loadingPaper}>
        <CircularProgress size="7em" />
      </Paper>
    );
  }

  // const recommendedPosts = posts.filter(({ _id }) => _id !== post._id);

  return (
    <Paper style={{ padding: "20px", borderRadius: "15px" }} elevation={6}>
      <div
        dangerouslySetInnerHTML={{
          __html: `
      <style>.h_iframe-aparat_embed_frame{position:relative;}.h_iframe-aparat_embed_frame .ratio{display:block;width:100%;height:auto;}.h_iframe-aparat_embed_frame iframe{position:absolute;top:0;left:0;width:100%;height:100%;}</style><div class="h_iframe-aparat_embed_frame"><span style="display: block;padding-top: 57%"></span><iframe src="${post.simLink}" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>
      `,
        }}
      ></div>
      <a
        href={post.simLink}
        target="_blank"
        style={{ textDecoration: "none"}}
      >
        در پنجره جدید باز کن
      </a>
    </Paper>
  );
};

export default Post;
